var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tree-source-panel",
      style: { "--width": _vm.width },
      on: {
        click: function ($event) {
          $event.stopPropagation()
        },
        scroll: _vm.onScroll,
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }